
/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/

var versionUpdate = (new Date().getDate()).toString() + (new Date().getMonth()+1).toString() + (new Date().getFullYear()).toString();

$(".testimonials-carousal-frame").load("../../testimonials-carousal-frame.html?v=" + versionUpdate);

$(".pricing-section-fundadmin").load("../../fund-administration-software/pricing-section.html?v=" + versionUpdate);

$(".pricing-section-portfolio").load("../../portfolio-management-software/pricing-section.html?v=" + versionUpdate);

$(".pricing-section-portal").load("../../investor-portal/pricing-section.html?v=" + versionUpdate);

$(".pricing-section-dealflow").load("../../dealflow/pricing-section.html?v=" + versionUpdate);

$(".pricing-section-spv").load("../../spv-administration-software/pricing-section.html?v=" + versionUpdate);

// Navbars
$(".navbar-frame").load("../../navbar.html?v=" + versionUpdate);

$(".navbar-frame-light").load("../../navbar-light.html?v=" + versionUpdate);

$(".spv-navbar-frame-light").load("../../spv-administration-software/spv-admin-navbar-light.html?v=" + versionUpdate);

$(".navbar-frame-blog").load("../../navbar-blog.html?v=" + versionUpdate);

$(".navbar-frame-blog-dealflow").load("../../navbar-blog-dealflow.html?v=" + versionUpdate);

$(".fund-admin-navbar-frame").load("../../fund-administration-software/fund-admin-navbar.html?v=" + versionUpdate);

$(".investor-portal-navbar-frame").load("../../investor-portal/investor-portal-navbar.html?v=" + versionUpdate);

$(".investor-portal-navbar-frame-light").load("../../investor-portal/investor-portal-navbar-light.html?v=" + versionUpdate);

$(".jcurve-navbar-frame").load("../../portfolio-management-software/jcurve-navbar.html?v=" + versionUpdate);

$(".dealflow-navbar-frame").load("../../dealflow/dealflow-navbar.html?v=" + versionUpdate);

$(".spv-admin-navbar-frame").load("../../spv-administration-software/spv-admin-navbar.html?v=" + versionUpdate);

$(".solutions-navbar-frame-light").load("../../solutions/solutions-navbar-light.html?v=" + versionUpdate);


$(".solutions-navbar-fundadmin-frame").load("../../solutions/solutions-navbar-fundadmin.html?v=" + versionUpdate);
$(".solutions-navbar-spvadmin-frame").load("../../solutions/solutions-navbar-spvadmin.html?v=" + versionUpdate);
$(".solutions-navbar-fundadmin-frame-light").load("../../solutions/solutions-navbar-fundadmin-light.html?v=" + versionUpdate);
$(".solutions-navbar-investor-portal-frame").load("../../solutions/solutions-navbar-investor-portal.html?v=" + versionUpdate);
$(".solutions-navbar-jcurve-frame").load("../../solutions/solutions-navbar-jcurve.html?v=" + versionUpdate);
$(".bundled-pricing-navbar-frame").load("../../vc-fund-management-software/bundled-pricing-navbar.html?v=" + versionUpdate);

// Footers

$(".footer-frame").load("../../footer.html?v=" + versionUpdate);
$(".investor-portal-footer-frame").load("../../investor-portal/investor-portal-footer.html?v=" + versionUpdate);
$(".jcurve-footer-frame").load("../../portfolio-management-software/jcurve-footer.html?v=" + versionUpdate);
$(".fund-admin-footer-frame").load("../../fund-administration-software/fund-admin-footer.html?v=" + versionUpdate);
$(".dealflow-footer-frame").load("../../dealflow/dealflow-footer.html?v=" + versionUpdate);
$(".solutions-footer-frame").load("../../solutions/solutions-footer.html?v=" + versionUpdate);

//customers

$(".customers-frame").load("../../customers-frame.html?v=" + versionUpdate);

//customers-signup

$(".customers-signup-frame").load("../../customers-signup-frame.html?v=" + versionUpdate);

//Comparison table

$(".table-frame").load("../../comparison-table.html?v=" + versionUpdate);


//Terms of service text

$(".terms-of-service-text-frame").load("../../terms-of-service-text.html?v=" + versionUpdate);


//Terms of service India text

$(".terms-of-service-india-text-frame").load("../../terms-of-service-india-text.html?v=" + versionUpdate);


// Integrations
$(".integrations-frame").load("../../dealflow/integrations-frame.html?v=" + versionUpdate);


//products for solutions landing pages
// 
//   $(".products-frame").load("../../solutions/products-frame.html?v=" + versionUpdate);
// 

// 
//   $(".products-card-frame").load("../../solutions/products-card-frame.html?v=" + versionUpdate);
// 

//schedule an appointment

$(".schedule-appointment-frame").load("../../schedule-appointment.html?v=" + versionUpdate);


//contact forms

$(".contact-form").load("../../contact-form.html?v=" + versionUpdate);
$(".solutions-contact-form").load("../../solutions-contact-form.html?v=" + versionUpdate);




  var errormodal = document.createElement("div")
  errormodal.innerHTML = `<div id="error-modal" role="dialog">
                  <div class="details-modal-overlay"></div>
                  <div class="error-modal-box">
                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="38" fill="#ff4954" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                    <span class="title">Oh snap!</span>
                    <p>An error has occurred while submitting form. You can email us at <a href="mailto:sales@getfundwave.com">sales@getfundwave.com</a></p>
                    <div class="button" onClick="hideErrorModal()">Dismiss</div>
                  </div>
                  <script type="text/javascript">
                  function hideErrorModal(){
                    $('#error-modal').addClass('hide-error-modal');
                    $('#error-modal').removeClass('show-error-modal');
                  }
                  </script>
                </div>`
  $("body").append(errormodal.firstChild)



  $(".our-values-frame").load("../../our-values-frame.html?v=" + versionUpdate);



  $("head").append(`
  <script type="text/javascript" async src="https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.9/dist/cookieconsent.js"><\/script> 
  <script type="text/javascript" async src="https://cdnjs.cloudflare.com/ajax/libs/jstimezonedetect/1.0.7/jstz.min.js"><\/script>
  <script type="text/javascript" src="/assets/js/geolocate.js"><\/script></script>
  `);


$(window).on("load", function() {
$.getScript('https://cdnjs.cloudflare.com/ajax/libs/jstimezonedetect/1.0.7/jstz.min.js', function(data){
  $.getScript("https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.9/dist/cookieconsent.js", function(data1){
    $.getScript("/assets/js/geolocate.js", function(data2){
        $("head").append('<script type="text/javascript" src="/assets/js/cookieconsent.js"><\/script></script>');
      })        
  })
})
})



  var chatbot = document.createElement("div")
  chatbot.innerHTML = `<script type="text/javascript">
    window.__be = window.__be || {};
    window.__be.id = "64b4dead2931a30007baae2b";
    (function() {
        var be = document.createElement('script'); be.type = 'text/javascript'; be.async = true;
        be.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.chatbot.com/widget/plugin.js';
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(be, s);
    })();
  </script>`
  $("head").append(chatbot.firstChild);

  // SHOW CALENDLY MODAL FOR SCHEDULING A DEMO
 




// Function to detect device type
function getDeviceType() {
  return /Mobi/.test(navigator.userAgent) ? 'mobile' : 'desktop';
}

// Function to get referrer URL
function getReferrer() {
  // console.log(window.location.pathname);
  let source = window.location.pathname ? window.location.pathname : 'direct';
  return source.replaceAll("/", "-");
}



// Function to update Calendly URL based on the region
function updateCalendlyUrl() {
  let deviceType = getDeviceType();
  let referrer = getReferrer();

  // Get the user's timezone and region
  let timezone = getUserTimeZone();
  let region = getRegionFromTimeZone(timezone);

  console.log("timezone", timezone, "region", region);

  // Initial Calendly URL for everyone outside Australia/New Zealand/Americas
  let calendlyUrl = "https://calendly.com/fundwave-demo/intro-call?hide_landing_page_details=1&primary_color=205081&text_color=323d47&hide_gdpr_banner=1";

  if (region === 'australia') {
    calendlyUrl = "https://calendly.com/fundwave-demo/introductory-call-clone?hide_landing_page_details=1&primary_color=205081&text_color=323d47&hide_gdpr_banner=1";
  } else if (region === 'america') {
    calendlyUrl = "https://calendly.com/fundwave-demo/introductory-call-americas?hide_landing_page_details=1&primary_color=205081&text_color=323d47&hide_gdpr_banner=1";
  }

  // Append UTM parameters to the Calendly URL
  calendlyUrl += `&utm_source=${referrer}&utm_medium=${deviceType}`;

  // Log the updated URL or set it to your button/link
  console.log("Updated Calendly URL:", calendlyUrl);

  var calendlymodal = document.createElement("div");
  calendlymodal.innerHTML = `<div id="calendly-modal" class="hide-calendly-modal" role="dialog">
                <div class="calendly-modal-overlay"></div>
                <div class="calendly-modal-close">x</div>
                  <div class="calendly-inline-widget" data-url="${calendlyUrl}" style="min-width:320px;height:90vh;max-height:1200px;top:10vh"></div>
                <script type="text/javascript">
                  function hideCalendlyModal(){
                    $('#calendly-modal').addClass('hide-calendly-modal');
                    $('#calendly-modal').removeClass('show-calendly-modal');
                  }
                  const calendlyoverlay = document.querySelector(".calendly-modal-overlay");
                  calendlyoverlay.addEventListener('click', hideCalendlyModal);
                  const calendlyCloseButton = document.querySelector(".calendly-modal-close");
                  calendlyCloseButton.addEventListener('click', hideCalendlyModal);
                  document.addEventListener('keydown', (event) => {
                    if (event.key === 'Escape' || event.keyCode === 27) {
                      hideCalendlyModal();
                    }
                  });
                </script>
              </div>`;
  $("body").append(calendlymodal.firstChild);
}

// Call the function to update the URL when the page loads

$.getScript('https://cdnjs.cloudflare.com/ajax/libs/jstimezonedetect/1.0.7/jstz.min.js', function (data) {
  updateCalendlyUrl();
  $("head").append('<link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet">');
  $("head").append('<script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>');
})
  
  $(document).ready(function () {
  const demoButtons = document.querySelectorAll(".show-demo");
  demoButtons.forEach(function (button) {
    button.addEventListener("click", function (event) {
      // Prevent default behavior (redirecting to another page)
      event.preventDefault();
      $('#calendly-modal').removeClass('hide-calendly-modal');
      $('#calendly-modal').addClass('show-calendly-modal');
    });
  });
});