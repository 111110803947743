
/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/

var versionUpdate = (new Date().getDate()).toString() + (new Date().getMonth()+1).toString() + (new Date().getFullYear()).toString();

$(".testimonials-carousal-frame").load("../../testimonials-carousal-frame.html?v=" + versionUpdate);

$(".pricing-section-fundadmin").load("../../fund-administration-software/pricing-section.html?v=" + versionUpdate);

$(".pricing-section-portfolio").load("../../portfolio-management-software/pricing-section.html?v=" + versionUpdate);

$(".pricing-section-portal").load("../../investor-portal/pricing-section.html?v=" + versionUpdate);

$(".pricing-section-dealflow").load("../../dealflow/pricing-section.html?v=" + versionUpdate);

$(".pricing-section-spv").load("../../spv-administration-software/pricing-section.html?v=" + versionUpdate);

// Navbars
$(".navbar-frame").load("../../navbar.html?v=" + versionUpdate);

$(".navbar-frame-light").load("../../navbar-light.html?v=" + versionUpdate);

$(".spv-navbar-frame-light").load("../../spv-administration-software/spv-admin-navbar-light.html?v=" + versionUpdate);

$(".navbar-frame-blog").load("../../navbar-blog.html?v=" + versionUpdate);

$(".navbar-frame-blog-dealflow").load("../../navbar-blog-dealflow.html?v=" + versionUpdate);

$(".fund-admin-navbar-frame").load("../../fund-administration-software/fund-admin-navbar.html?v=" + versionUpdate);

$(".investor-portal-navbar-frame").load("../../investor-portal/investor-portal-navbar.html?v=" + versionUpdate);

$(".investor-portal-navbar-frame-light").load("../../investor-portal/investor-portal-navbar-light.html?v=" + versionUpdate);

$(".jcurve-navbar-frame").load("../../portfolio-management-software/jcurve-navbar.html?v=" + versionUpdate);

$(".dealflow-navbar-frame").load("../../dealflow/dealflow-navbar.html?v=" + versionUpdate);

$(".spv-admin-navbar-frame").load("../../spv-administration-software/spv-admin-navbar.html?v=" + versionUpdate);

$(".solutions-navbar-frame-light").load("../../solutions/solutions-navbar-light.html?v=" + versionUpdate);


$(".solutions-navbar-fundadmin-frame").load("../../solutions/solutions-navbar-fundadmin.html?v=" + versionUpdate);
$(".solutions-navbar-spvadmin-frame").load("../../solutions/solutions-navbar-spvadmin.html?v=" + versionUpdate);
$(".solutions-navbar-fundadmin-frame-light").load("../../solutions/solutions-navbar-fundadmin-light.html?v=" + versionUpdate);
$(".solutions-navbar-investor-portal-frame").load("../../solutions/solutions-navbar-investor-portal.html?v=" + versionUpdate);
$(".solutions-navbar-jcurve-frame").load("../../solutions/solutions-navbar-jcurve.html?v=" + versionUpdate);
$(".bundled-pricing-navbar-frame").load("../../vc-fund-management-software/bundled-pricing-navbar.html?v=" + versionUpdate);

// Footers

$(".footer-frame").load("../../footer.html?v=" + versionUpdate);
$(".investor-portal-footer-frame").load("../../investor-portal/investor-portal-footer.html?v=" + versionUpdate);
$(".jcurve-footer-frame").load("../../portfolio-management-software/jcurve-footer.html?v=" + versionUpdate);
$(".fund-admin-footer-frame").load("../../fund-administration-software/fund-admin-footer.html?v=" + versionUpdate);
$(".dealflow-footer-frame").load("../../dealflow/dealflow-footer.html?v=" + versionUpdate);
$(".solutions-footer-frame").load("../../solutions/solutions-footer.html?v=" + versionUpdate);

//customers

$(".customers-frame").load("../../customers-frame.html?v=" + versionUpdate);

//customers-signup

$(".customers-signup-frame").load("../../customers-signup-frame.html?v=" + versionUpdate);

//Comparison table

$(".table-frame").load("../../comparison-table.html?v=" + versionUpdate);


//Terms of service text

$(".terms-of-service-text-frame").load("../../terms-of-service-text.html?v=" + versionUpdate);


//Terms of service India text

$(".terms-of-service-india-text-frame").load("../../terms-of-service-india-text.html?v=" + versionUpdate);


// Integrations
$(".integrations-frame").load("../../dealflow/integrations-frame.html?v=" + versionUpdate);


//products for solutions landing pages
// 
//   $(".products-frame").load("../../solutions/products-frame.html?v=" + versionUpdate);
// 

// 
//   $(".products-card-frame").load("../../solutions/products-card-frame.html?v=" + versionUpdate);
// 

//schedule an appointment

$(".schedule-appointment-frame").load("../../schedule-appointment.html?v=" + versionUpdate);


//contact forms

$(".contact-form").load("../../contact-form.html?v=" + versionUpdate);
$(".solutions-contact-form").load("../../solutions-contact-form.html?v=" + versionUpdate);




  var errormodal = document.createElement("div")
  errormodal.innerHTML = `<div id="error-modal" role="dialog">
                  <div class="details-modal-overlay"></div>
                  <div class="error-modal-box">
                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="38" fill="#ff4954" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                    <span class="title">Oh snap!</span>
                    <p>An error has occurred while submitting form. You can email us at <a href="mailto:sales@getfundwave.com">sales@getfundwave.com</a></p>
                    <div class="button" onClick="hideErrorModal()">Dismiss</div>
                  </div>
                  <script type="text/javascript">
                  function hideErrorModal(){
                    $('#error-modal').addClass('hide-error-modal');
                    $('#error-modal').removeClass('show-error-modal');
                  }
                  </script>
                </div>`
  $("body").append(errormodal.firstChild)



  $(".our-values-frame").load("../../our-values-frame.html?v=" + versionUpdate);



  $("head").append(`
  <script type="text/javascript" async src="https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.9/dist/cookieconsent.js"><\/script> 
  <script type="text/javascript" async src="https://cdnjs.cloudflare.com/ajax/libs/jstimezonedetect/1.0.7/jstz.min.js"><\/script>
  <script type="text/javascript" src="/assets/js/geolocate.js"><\/script></script>
  `);


$(window).on("load", function() {
$.getScript('https://cdnjs.cloudflare.com/ajax/libs/jstimezonedetect/1.0.7/jstz.min.js', function(data){
  $.getScript("https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.9/dist/cookieconsent.js", function(data1){
    $.getScript("/assets/js/geolocate.js", function(data2){
        $("head").append('<script type="text/javascript" src="/assets/js/cookieconsent.js"><\/script></script>');
      })        
  })
})
})



  var chatbot = document.createElement("div")
  chatbot.innerHTML = `<script type="text/javascript">
    window.__be = window.__be || {};
    window.__be.id = "64b4dead2931a30007baae2b";
    (function() {
        var be = document.createElement('script'); be.type = 'text/javascript'; be.async = true;
        be.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.chatbot.com/widget/plugin.js';
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(be, s);
    })();
  </script>`
  $("head").append(chatbot.firstChild);

  // SHOW CALENDLY MODAL FOR SCHEDULING A DEMO
 




// Function to detect device type
function getDeviceType() {
  return /Mobi/.test(navigator.userAgent) ? 'mobile' : 'desktop';
}

// Function to get referrer URL
function getReferrer() {
  // console.log(window.location.pathname);
  let source = window.location.pathname ? window.location.pathname : 'direct';
  return source.replaceAll("/", "-");
}



// Function to update Calendly URL based on the region
function updateCalendlyUrl() {
  let deviceType = getDeviceType();
  let referrer = getReferrer();

  // Get the user's timezone and region
  let timezone = getUserTimeZone();
  let region = getRegionFromTimeZone(timezone);

  console.log("timezone", timezone, "region", region);

  // Initial Calendly URL for everyone outside Australia/New Zealand/Americas
  let calendlyUrl = "https://calendly.com/fundwave-demo/intro-call?hide_landing_page_details=1&primary_color=205081&text_color=323d47&hide_gdpr_banner=1";

  if (region === 'australia') {
    calendlyUrl = "https://calendly.com/fundwave-demo/introductory-call-clone?hide_landing_page_details=1&primary_color=205081&text_color=323d47&hide_gdpr_banner=1";
  } else if (region === 'america') {
    calendlyUrl = "https://calendly.com/fundwave-demo/introductory-call-americas?hide_landing_page_details=1&primary_color=205081&text_color=323d47&hide_gdpr_banner=1";
  }

  // Append UTM parameters to the Calendly URL
  calendlyUrl += `&utm_source=${referrer}&utm_medium=${deviceType}`;

  // Log the updated URL or set it to your button/link
  console.log("Updated Calendly URL:", calendlyUrl);

  var calendlymodal = document.createElement("div");
  calendlymodal.innerHTML = `<div id="calendly-modal" class="hide-calendly-modal" role="dialog">
                <div class="calendly-modal-overlay"></div>
                <div class="calendly-modal-close">x</div>
                  <div class="calendly-inline-widget" data-url="${calendlyUrl}" style="min-width:320px;height:90vh;max-height:1200px;top:10vh"></div>
                <script type="text/javascript">
                  function hideCalendlyModal(){
                    $('#calendly-modal').addClass('hide-calendly-modal');
                    $('#calendly-modal').removeClass('show-calendly-modal');
                  }
                  const calendlyoverlay = document.querySelector(".calendly-modal-overlay");
                  calendlyoverlay.addEventListener('click', hideCalendlyModal);
                  const calendlyCloseButton = document.querySelector(".calendly-modal-close");
                  calendlyCloseButton.addEventListener('click', hideCalendlyModal);
                  document.addEventListener('keydown', (event) => {
                    if (event.key === 'Escape' || event.keyCode === 27) {
                      hideCalendlyModal();
                    }
                  });
                </script>
              </div>`;
  $("body").append(calendlymodal.firstChild);
  if (window.openCalendlyScheduleModal) window.openCalendlyScheduleModal();
}

// Call the function to update the URL when the page loads

$.getScript('https://cdnjs.cloudflare.com/ajax/libs/jstimezonedetect/1.0.7/jstz.min.js', function (data) {
  updateCalendlyUrl();
  $("head").append('<link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet">');
  $("head").append('<script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>');
})
  
  $(document).ready(function () {
  const demoButtons = document.querySelectorAll(".show-demo");
  demoButtons.forEach(function (button) {
    button.addEventListener("click", function (event) {
      // Prevent default behavior (redirecting to another page)
      event.preventDefault();
      $('#calendly-modal').removeClass('hide-calendly-modal');
      $('#calendly-modal').addClass('show-calendly-modal');
    });
  });
});

























// Constants for storage
const STORAGE_KEY = 'demo_pricing_behavior';
const STORAGE_DURATION = 30 * 24 * 60 * 60 * 1000; // 30 days for overall storage
const DAILY_MODAL_KEY = 'modal_last_shown';
const ONE_DAY = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

console.log('Initializing discount modal system...');

$(document).ready(function () {
  const modalStyles = `
    <style>
      #discount-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999999;
        transition: opacity 0.3s ease, visibility 0.3s ease;
        pointer-events: none;
      }

      #discount-modal.show-discount-modal {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }

      #discount-modal.hide-discount-modal {
        opacity: 0;
        visibility: hidden;
      }
      
      .discount-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(4px);
      }
      
      .discount-modal-box {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.95);
        background: white;
        padding: 3rem;
        border-radius: 16px;
        max-width: 700px;
        width: 90%;
        text-align: center;
        z-index: 1000000;
        box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
        opacity: 0;
        transition: transform 0.4s ease, opacity 0.3s ease;
      }
      
      .show-discount-modal .discount-modal-box {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
      
      .discount-modal-close {
        position: absolute;
        right: 1.25rem;
        top: 1.25rem;
        cursor: pointer;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background: #f3f4f6;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        color: #6b7280;
        transition: all 0.2s ease;
        z-index: 1;
      }
      
      .discount-modal-close:hover {
        background: #e5e7eb;
        color: #374151;
      }

      .discount-modal-icon {
        width: 80px;
        height: 80px;
        background: rgba(31, 79, 125, 0.1);
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 2rem;
      }

      .discount-modal-icon svg {
        width: 40px;
        height: 40px;
        color: #1f4f7d;
      }
      
      #discount-modal h3 {
        color: #111827;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
        line-height: 1.3;
      }
      
      #discount-modal p {
        color: #6b7280;
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 1.5rem;
      }
      
      #discount-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1.5rem;
      }
      
      #discount-email {
        padding: 0.875rem 1rem;
        border: 1.5px solid #e5e7eb;
        border-radius: 8px;
        font-size: 1rem;
        transition: border-color 0.2s ease;
        outline: none;
      }
      
      #discount-email:focus {
        border-color: #1f4f7d;
        box-shadow: 0 0 0 4px rgba(31, 79, 125, 0.1);
      }

      #discount-form button {
        background: #1f4f7d;
        color: white;
        padding: 1rem 2rem;
        border-radius: 8px;
        font-weight: 500;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease;
        font-size: 1rem;
      }
      
      #discount-form button:hover {
        background: #183d61;
        transform: translateY(-1px);
      }

      .form-step {
        transition: opacity 0.3s ease;
      }

      .form-step.hidden {
        display: none;
        opacity: 0;
      }

      .form-step.visible {
        display: block;
        opacity: 1;
      }

      .success-icon {
        width: 80px;
        height: 80px;
        background: rgba(31, 79, 125, 0.1);
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 2rem;
      }

      .success-icon svg {
        width: 40px;
        height: 40px;
        color: #1f4f7d;
      }

      .success-message h3 {
        color: #1f4f7d;
        margin-bottom: 1rem;
      }

      .success-message p {
        color: #6b7280;
        font-size: 1.1rem;
        line-height: 1.6;
      }
    </style>
  `;

  $('head').append(modalStyles);

  const modalHTML = `
    <div id="discount-modal" class="hide-discount-modal" role="dialog" aria-hidden="true">
      <div class="discount-modal-overlay"></div>
      <div class="discount-modal-box">
        <div class="discount-modal-close">×</div>
        
        <div class="form-step visible" id="step-1">
          <div class="discount-modal-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
  <circle cx="12" cy="7" r="4"></circle>
</svg>
          </div>
          <h3>Get personalized consultation from a Fundwave expert.</h3>
          <!-- <p>Discover with an expert how Fundwave can streamline your fund management process.</p> --!>
          <form id="discount-form" action="https://signup.fundwave.cloud/" method="POST" target="hidden-form-response">
            <input type="hidden" name="source" value="pricing_modal">
            <input type="hidden" name="path" value="">
            <input type="email" id="discount-email" name="email" placeholder="Enter your work email" required style="text-align: center; font-size: 16px;">
            <style>
              ::placeholder {
                text-align: center;
              }
            </style>
            <button type="submit" class="button">Submit</button>
          </form>
        </div>

        <div class="form-step hidden" id="step-2">
          <div class="success-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
            </svg>
          </div>
          <div class="success-message">
            <h3>Thanks! We'll be in touch</h3>
          </div>
        </div>
      </div>
    </div>
    <iframe name="hidden-form-response" style="display:none;"></iframe>
`;


  // Remove any existing modal before adding new one
  $('#discount-modal').remove();
  $('body').append(modalHTML);

  // Event Handlers
  $(document).on('submit', '#discount-form', function (event) {
    event.preventDefault();
    event.stopPropagation();
    handleDiscountFormSubmit(event);
  });

  $(document).on('click', '.discount-modal-close', function (event) {
    event.preventDefault();
    event.stopPropagation();
    hideDiscountModal();
  });

  $(document).on('click', '.discount-modal-overlay', function (event) {
    event.preventDefault();
    event.stopPropagation();
    hideDiscountModal();
  });

  // Prevent modal clicks from bubbling to other elements
  $(document).on('click', '#discount-modal', function (event) {
    event.stopPropagation();
  });

  $(document).keydown(function (e) {
    if (e.key === "Escape" && !$('#discount-modal').hasClass('hide-discount-modal')) {
      hideDiscountModal();
    }
  });
});

// Helper functions
function getBehaviorData() {
  const stored = localStorage.getItem(STORAGE_KEY);
  if (!stored) return {};

  const data = JSON.parse(stored);
  const timestamp = data._timestamp || 0;

  if (Date.now() - timestamp > STORAGE_DURATION) {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(DAILY_MODAL_KEY);
    return {};
  }

  return data;
}

function saveBehaviorData(data) {
  data._timestamp = Date.now();
  localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
}

function canShowModal() {
  const behavior = getBehaviorData();

  // Never show if email was submitted
  if (behavior.emailSubmitted) {
    console.log('Email previously submitted, not showing modal');
    return false;
  }

  // Check if shown in last 24 hours
  const lastShown = localStorage.getItem(DAILY_MODAL_KEY);
  if (lastShown) {
    const timeSinceLastShown = Date.now() - parseInt(lastShown);
    if (timeSinceLastShown < ONE_DAY) {
      console.log('Modal shown within last 24 hours, not showing');
      return false;
    }
  }

  return true;
}

function showDiscountModal() {
  if (!canShowModal()) return;

  const $modal = $('#discount-modal');
  $modal
    .removeClass('hide-discount-modal')
    .addClass('show-discount-modal')
    .attr('aria-hidden', 'false');

  // Record showing time
  localStorage.setItem(DAILY_MODAL_KEY, Date.now().toString());

  const behavior = getBehaviorData();
  behavior.modalShown = true;
  behavior.modalShownTimestamp = Date.now();
  saveBehaviorData(behavior);
}

function hideDiscountModal() {
  const $modal = $('#discount-modal');
  $modal
    .removeClass('show-discount-modal')
    .addClass('hide-discount-modal')
    .attr('aria-hidden', 'true');
}




function getFormData(form) {
  var elements = form.elements;

  var fields = Object.keys(elements).map(function (k) {
    if (elements[k].name !== undefined) {
      return elements[k].name;
      // special case for Edge's html collection
    } else if (elements[k].length > 0) {
      return elements[k].item(0).name;
    }
  }).filter(function (item, pos, self) {
    return self.indexOf(item) == pos && item;
  });

  console.log("elemds: ", elements);
  console.log(fields);

  var formData = {};
  fields.forEach(function (name) {
    var element = elements[name];

    // singular form elements just have one value
    formData[name] = element.value;

    // when our element has multiple items, get their values
    if (element.length) {
      var data = [];
      for (var i = 0; i < element.length; i++) {
        var item = element.item(i);
        if (item.checked || item.selected) {
          data.push(item.value);
        }
      }
      formData[name] = data.join(', ');
    }
  });

  // add form-specific values into the data
  formData.formDataNameOrder = JSON.stringify(fields);
  formData.formGoogleSheetName = "discount_modal_responses";
  formData.source = "pricing_modal";

  // If there's no path field, add it
  if (!formData.path) formData.path = window.location.pathname;

  console.log('Form data being submitted:', formData);
  return formData;
}

function handleDiscountFormSubmit(event) {
  event.preventDefault();

  const form = event.target;
  const formData = getFormData(form);
  console.log(formData);

  // Disable submit button during submission
  const $button = $(form).find('button');
  const prevButtonText = $button.text();
  $button.prop('disabled', true);
  $button.text('Submitting...');

  // Send to server
  const url = 'https://signup.fundwave.cloud/';
  const xhr = new XMLHttpRequest();
  xhr.open('POST', url);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === xhr.DONE) {
      if (xhr.status >= 200 && xhr.status < 400) {
        // Log the response
        console.log('Form submission response:', xhr.responseText);

        try {
          const response = JSON.parse(xhr.responseText);
          if (response.result === "success") {
            // Store submission
            const behavior = getBehaviorData();
            behavior.emailSubmitted = true;
            behavior.emailSubmittedTimestamp = Date.now();
            saveBehaviorData(behavior);

            // Switch to success step
            $('#step-1').removeClass('visible').addClass('hidden');
            $('#step-2').removeClass('hidden').addClass('visible');

            // Close modal after delay
            setTimeout(hideDiscountModal, 5000);
          }
        } catch (e) {
          console.error('Error parsing response:', e);
          $button.prop('disabled', false);
          $button.text(prevButtonText);
        }
      } else {
        // Re-enable button on error
        console.error('Form submission failed');
        $button.prop('disabled', false);
        $button.text(prevButtonText);
      }
    }
  };

  // Encode and send form data
  const encoded = Object.keys(formData).map(function (k) {
    return encodeURIComponent(k) + "=" + encodeURIComponent(formData[k]);
  }).join('&');
  xhr.send(encoded);
}



$(document).ready(function () {
  $('.show-demo').on('click', function (event) {
    event.stopPropagation();
    const behavior = getBehaviorData();
    behavior.clickedDemo = true;
    behavior.lastDemoClick = Date.now();
    saveBehaviorData(behavior);
  });

  if (window.location.pathname.includes('/pricing')) {
    const behavior = getBehaviorData();

    if (behavior.clickedDemo && !behavior.modalShown && canShowModal()) {
      setTimeout(() => {
        showDiscountModal();
      }, 60000);
    }
  }
});
