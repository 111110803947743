/*
|--------------------------------------------------------------------------
| Core
|--------------------------------------------------------------------------
|
| The start point of the project. Include jQuery, Bootstrap and required
| plugins and define page object. This file is required.
|
*/
require('../../plugin/thesaas/js/loaders/core.js');

/*
|--------------------------------------------------------------------------
| Custom script
|--------------------------------------------------------------------------
|
| Write your custom JavaScrip code. Feel free to split your code to several
| files and import the other files here or inside script.js.
|
*/
require('./script.js');
